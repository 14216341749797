<template>
  <nav class="header-menu__container">
    <ul class="header-menu__main">
      <li
        v-for="(parent, parentIndex) in menuList"
        :key="parentIndex"
        class="header-menu__parent-item"
        @mouseenter="onParentElementFocus(parentIndex)"
        @mouseleave="clearHoveredElement"
      >
        <a-link
          :class="parentItemClasses"
          :to="prop('Link', parent)"
          :open-in-new-tab="prop('OpenInNewTab', parent)"
          tabindex="0"
          v-bind="getItemAttrs(parent)"
          @focus="onParentElementFocus(parentIndex)"
          @blur="onParentElementBlur"
        >
          <span>
            {{ prop('Name', parent) }}
          </span>
        </a-link>

        <div
          v-show="isSubItemsVisible"
          class="header-menu__columns-container"
          :class="getHeaderMenuDynamicClass(parentIndex)"
          @mouseout="clearHoveredElement(parentIndex)"
          @click="hideSubItems"
        >
          <div class="header-menu__column">
            <ul v-if="ifHasChildren(parent)" class="header-menu__submenu">
              <li
                v-for="(subMenu, subMenuIndex) in parent.ChildItems"
                :key="subMenuIndex"
                @mouseover="
                  setHoveredElement(subMenu, parentIndex, subMenuIndex)
                "
              >
                <a-link
                  :class="getSubmenuDynamicClass(subMenuIndex)"
                  tabindex="0"
                  :to="prop('Link', subMenu)"
                  :open-in-new-tab="prop('OpenInNewTab', parent)"
                  @focus="setHoveredElement(subMenu, parentIndex, subMenuIndex)"
                  @blur="onChildElementBlur"
                >
                  {{ prop('Name', subMenu) }}
                </a-link>

                <div
                  v-if="isShownSubMenuChildren(parentIndex, subMenuIndex)"
                  class="header-menu__submenu-column"
                >
                  <ul class="header-menu__submenu header-menu__submenu_child">
                    <li
                      v-for="(subMenuChild,
                      subMenuChildIndex) in hoveredElement"
                      :key="subMenuChildIndex"
                    >
                      <a-link
                        class="header-menu__submenu-text_lighter"
                        :class="submenuChildDynamicClass"
                        tabindex="0"
                        :to="prop('Link', subMenuChild)"
                      >
                        {{ prop('Name', subMenuChild) }}
                      </a-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { pathOr, prop } from 'ramda'
import { mapGetters } from 'vuex'

import { ICON } from 'shared/AIcon'

const PARENT_ITEM_CLASS = 'header-menu__main-text'
const SUBMENU_ITEM_CLASS = 'header-menu__submenu-text'

export default {
  name: 'AHeaderMenu',
  props: {
    menuList: {
      type: Array,
      required: true
    }
  },
  consts: {
    flLogoIconProps: {
      icon: ICON.FL,
      width: 78,
      height: 25,
      fill: '#fff'
    }
  },
  data() {
    return {
      hoveredElement: [],
      hoveredIndexParent: null,
      hoveredIndexChild: null,
      isSubItemsVisible: true
    }
  },
  computed: {
    ...mapGetters({
      isHeaderCollapsed: 'isHeaderCollapsed'
    }),
    parentItemClasses() {
      return [
        PARENT_ITEM_CLASS,
        this.isHeaderCollapsed
          ? 'header-menu__main-text_collapsed'
          : 'header-menu__main-text_expanded'
      ]
    },
    submenuChildDynamicClass() {
      return [SUBMENU_ITEM_CLASS]
    }
  },
  methods: {
    prop,
    ifHasChildren({ ChildItems } = {}) {
      return pathOr(0, ['length'], ChildItems) > 0
    },
    isShownSubMenuChildren(parentIndex, subMenuIndex) {
      return (
        parentIndex === this.hoveredIndexParent &&
        subMenuIndex === this.hoveredIndexChild &&
        this.hoveredElement.length > 0
      )
    },
    onParentElementFocus(parentIndex) {
      if (parentIndex !== this.hoveredIndexParent) {
        this.hoveredIndexChild = null
      }

      this.hoveredIndexParent = parentIndex

      this.clearHoveredElement(parentIndex)
    },
    isChildMenuItem(el) {
      if (!el || !el.classList) return false

      return el.classList.contains(SUBMENU_ITEM_CLASS)
    },
    isParentMenuItem(el) {
      if (!el || !el.classList) return false

      return el.classList.contains(PARENT_ITEM_CLASS)
    },
    closeDropDown() {
      this.hoveredElement = []
      this.hoveredIndexParent = null
      this.hoveredIndexChild = null
    },
    onParentElementBlur(event) {
      const { relatedTarget } = event

      if (!this.isChildMenuItem(relatedTarget)) {
        this.closeDropDown()
      }
    },
    onChildElementBlur(event) {
      const { relatedTarget } = event

      if (
        !this.isChildMenuItem(relatedTarget) &&
        !this.isParentMenuItem(relatedTarget)
      ) {
        this.closeDropDown()
      }
    },
    setHoveredElement(parent, parentIndex, childIndex) {
      this.hoveredElement = pathOr([], ['ChildItems'], parent)
      this.hoveredIndexParent = parentIndex
      this.hoveredIndexChild = childIndex
    },
    clearHoveredElement(index) {
      this.isSubItemsVisible = true

      if (index == null || index !== this.hoveredIndexParent) {
        this.closeDropDown()
      }
    },
    getSubmenuDynamicClass(index) {
      return {
        [SUBMENU_ITEM_CLASS]: true,
        'header-menu__submenu-text_hovered': index === this.hoveredIndexChild
      }
    },
    hideSubItems() {
      this.isSubItemsVisible = false
    },
    getItemAttrs(item) {
      return (item && item.attrs) || {}
    },
    getHeaderMenuDynamicClass(parentIndex) {
      return {
        'header-menu__columns-container--active':
          this.hoveredIndexParent === parentIndex
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-menu__container {
  z-index: $z-index-header-menu-container;

  @include desktop-lg {
    width: 780px;
  }
}

.header-menu__main {
  display: flex;
  width: 100%;
  align-items: center;
  z-index: 101;
  transform: translate3d(0, 0, 0);
}

$overlapHeight: 5px;

.header-menu__columns-container {
  display: none;
  align-items: stretch;
  position: absolute;
  top: calc(100% - #{$overlapHeight});
  left: 0;
  transition: width 5s;
}

.header-menu__submenu {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  font-size: 14px;
  line-height: 32px;
  padding: 11px 23px 17px 11px;
  color: white;
  background-color: $c--black;
  z-index: 101;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 13px solid black;
  }
}

.header-menu__submenu_child {
  min-height: 100%;

  &::before {
    display: none;
  }
}

.header-menu__main-text {
  position: relative;
  display: flex;
  align-items: center;

  font-weight: bold;
  cursor: pointer;
  color: white;
  padding: 20px 0;
  transition: font-size 0.3s, color 0.3s;
}

.header-menu__parent-item {
  position: relative;
  flex-shrink: 0;
  margin-left: 35px;

  &:first-child {
    margin-left: 0;
  }

  &.header-menu__parent-item-flex {
    display: flex;
    align-items: center;
  }

  .header-menu__columns-container--active {
    display: flex;
  }

  &:hover > .header-menu__main-text {
    color: $c--gray-dark;
  }

  &.header-menu__parent-item-flex:hover > .header-menu__main-text {
    color: white;
  }

  &.header-menu__parent-item-flex > .header-menu__main-text:hover {
    color: $c--gray-dark;
  }

  .header-menu__parent-item-suffix {
    margin-left: 5px;
  }
}

.header-menu__submenu-column {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 100%;
  top: 0;
}

.forexlive-icon {
  margin-left: 5px;
}

.header-menu__main-text_collapsed {
  font-size: 13px;
}

.header-menu__main-text_expanded {
  font-size: 15px;
}

.header-menu__submenu-text {
  position: relative;
  display: flex;

  font-weight: bold;
  font-size: 15px;
  padding-right: 15px;
  color: white;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid white;

    .header-menu__submenu-child {
      display: flex;
      flex-direction: column;
    }
  }
}

.header-menu__submenu-text_lighter {
  font-weight: normal;
}

.header-menu__submenu-text_hovered {
  border-bottom: 1px solid white;
}
</style>
